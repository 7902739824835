import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/Accordion.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionContent"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/AccordionContent.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionPanel"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/AccordionPanel.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionTitle"] */ "/vercel/path0/node_modules/flowbite-react/dist/esm/components/Accordion/AccordionTitle.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(pages)/(adelaide)/clarinet-lessons-adelaide/images/3.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(pages)/(adelaide)/songwriting-lessons-adelaide/images/3.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FreeTrialCountdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/GeneralFreeTrialSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/MusiCalPopup.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/PrimaryFeatures.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/carbon-removal.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/credibility/Adelaide Examiner.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/credibility/asme.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/credibility/business-sa.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/credibility/state-brand.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/credibility/stripe-climate-member.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/customer-avatars/amyjane.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/customer-avatars/leah.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/customer-avatars/nicola.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/floral-corner-2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/genres/genre_masonry.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/great_company/Australian Piano Warehouse.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/great_company/Erebus Reactor.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/great_company/Eternal Arts Collective.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/great_company/Global Music Revolution.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/great_company/Muso World.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/great_company/Sounds Of Adelaide.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/green-squiggly-arrow.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/home-hero-2.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/cello.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/drums.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/flute.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/guitar.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/no-instrument-no-probem.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/piano.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/singing.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/ukulele.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/violinviola.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/instruments/windbrass.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/leah-with-mic.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/scale-new-heights-banner.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/support_local/Business SA.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/support_local/SA Brand.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/support_local/Stripe Climate Member.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Adam.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Annabel.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Elizabeth.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Ethan.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Jaymee.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Jennifer.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Nicola.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Niv.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Powing.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Sam.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Sepideh.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/team/Todd.jpg");
