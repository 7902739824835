'use client';
import { calendlyConfig } from '../../calendlyConfig';
import Appointment from './Appointment';
import { CalendlyEmbed } from './CalendlyEmbed';

export function FreeTrialRoutingForm() {
  return (
    <div className="sm:px-60 w-full">
      <Appointment autoFocus={false} onClose={() => {}}></Appointment>
    </div>
  );
}
