'use client';
import { useRef } from 'react';
import { AvatarStars } from './AvatarStars';
import { FreeTrialRoutingForm } from './FreeTrialRoutingForm';
import { useIntersection } from 'react-use';

export default function GeneralFreeTrialSection() {
  const intersectionRef = useRef<HTMLElement>(null);
  const intersection = useIntersection(intersectionRef as any, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  return (
    <section ref={intersectionRef} id="freetrialsection" className="bg-gray-50">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-2xl font-bold text-green-600">Free Music Lesson</p>
          <h2 className="w-full text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Book A Free Trial Lesson. ⌛️ Don’t Miss Out, Limited Spots ⌛️
          </h2>
          <p className="mt-6  text-lg leading-8 text-gray-600">
            Everyone ends the trial lesson having learnt one new thing.
          </p>
          <div className="mt-10 flex w-full flex-col items-center justify-center gap-x-6 ">
            {intersection && intersection.intersectionRatio > 0 && (
              <FreeTrialRoutingForm />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
